body{color:#323232;font-family:'Open Sans',sans-serif !important;padding:0;margin:0;font-size:15px;direction:ltr;font-weight:400;background:#fff}
input{-webkit-appearance:none}
input:focus,select:focus,textarea:focus,button:focus{outline:none !important}
input[type=radio]{-webkit-appearance:radio}
input[type=checkbox]{-webkit-appearance:checkbox}
input[type=file]{font-size:15px}
.clear{clear:both}
@-webkit-viewport{width:device-width}
@-moz-viewport{width:device-width}
@-ms-viewport{width:device-width}
@-o-viewport{width:device-width}
@viewport{width:device-width}
@-ms-viewport{width:auto !important}
::-webkit-scrollbar{width:12px}
::-webkit-scrollbar-track{background-color:#eaeaea;border-left:1px solid #cecece}
::-webkit-scrollbar-thumb{background-color:#cecece}
::-webkit-scrollbar-thumb:hover{background-color:#aaa}
::-webkit-scrollbar-track{border-radius:0;box-shadow:none;border:0}
::-webkit-scrollbar-thumb{border-radius:0;box-shadow:none;border:0}
hr{margin:20px 0;border:0;border-top:1px solid #eee;border-bottom:0}
.red{color:#00a0d6;letter-spacing:normal}
.header{height:46px;background:white;width:100%;position:fixed;top:0;z-index:999;border-bottom:1px solid #ccc}
.header .logo{font-family:'Alegreya Sans',sans-serif;color:#5b5b5b;font-size:24px;letter-spacing:7px;text-transform:uppercase;margin-left:0;position:absolute;margin-top:0;background-size:235px 36px;width:235px;height:36px;margin-top:4px}
.header .top-menu{position:absolute;right:20px}
.dropup.open>.dropdown-toggle,.dropdown.open>.dropdown-toggle{border-color:#ddd}
.dropdown-menu{min-width:175px;position:absolute;top:100%;left:0;z-index:1000;display:none;float:left;list-style:none;text-shadow:none;padding:0;margin:10px 0 0 0;background-color:#fffff;box-shadow:5px 5px rgba(102,102,102,0.1);border:1px solid #eee;font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;border-radius:4px}
.dropdown-menu li.divider{background:#f1f3f6}
.dropdown-menu li>a{padding:8px 14px;color:#555;text-decoration:none;display:block;clear:both;font-weight:400;line-height:18px;white-space:nowrap}
.dropdown-menu li>a>[class^="fa-"],.dropdown-menu li>a>[class*=" fa-"]{color:#888}
.dropdown-menu li>a>[class^="icon-"],.dropdown-menu li>a>[class*=" icon-"]{color:#666}
.dropdown-menu li>a>[class^="glyphicon-"],.dropdown-menu li>a>[class*=" glyphicon-"]{color:#888}
.dropdown-menu li>a:hover,.dropdown-menu .active>a,.dropdown-menu .active>a:hover{text-decoration:none;background-image:none;background-color:#f6f6f6;color:#555;-webkit-filter:none;filter:none}
.dropdown-menu.bottom-up{top:auto;bottom:100%;margin-bottom:2px}
.dropdown>.dropdown-menu,.dropdown-toggle>.dropdown-menu,.btn-group>.dropdown-menu{margin-top:10px}
.dropdown>.dropdown-menu:before,.dropdown-toggle>.dropdown-menu:before,.btn-group>.dropdown-menu:before{position:absolute;top:-8px;right:9px;left:auto;display:inline-block !important;border-right:8px solid transparent;border-bottom:8px solid #e0e0e0;border-left:8px solid transparent;content:''}
.dropdown>.dropdown-menu:after,.dropdown-toggle>.dropdown-menu:after,.btn-group>.dropdown-menu:after{position:absolute;top:-7px;right:10px;left:auto;display:inline-block !important;border-right:7px solid transparent;border-bottom:7px solid #fff;border-left:7px solid transparent;content:''}
.dropdown>.dropdown-menu.pull-left:before,.dropdown-toggle>.dropdown-menu.pull-left:before,.btn-group>.dropdown-menu.pull-left:before{left:auto;right:9px}
.dropdown>.dropdown-menu.pull-left:after,.dropdown-toggle>.dropdown-menu.pull-left:after,.btn-group>.dropdown-menu.pull-left:after{left:auto;right:10px}
.dropdown>.dropdown-menu.pull-right:before,.dropdown-toggle>.dropdown-menu.pull-right:before,.btn-group>.dropdown-menu.pull-right:before{left:auto;right:9px}
.dropdown-menu .typcn{font-size:20px}
.nav.pull-right>li>.dropdown-menu,.nav>li>.dropdown-menu.pull-right{right:0;left:auto}
.dropdown:hover .dropdown-menu{display:block;margin-top:0}
.dropdown.avatar img{float:left;margin-top:-5px;margin-right:5px;height:29px;display:inline-block}
.dropdown.avatar>a{color:#000}
.img-circle{border-radius:50%}
.nav-mobile{display:none}
.page-container{margin:0;padding:0;position:relative;margin-top:47px}
.page-container:before,.page-container:after{content:" ";display:table}
.page-container:after{clear:both}
.page-sidebar{background-color:#f1f1f1;width:235px;float:left;position:fixed;margin-right:-100%}
.current_time_div{font-family:'Open Sans',sans-serif !important;text-align:center;margin-top:27px;-webkit-transform-style:preserve-3d}
.current_time{font-size:58px;font-weight:200;line-height:52px}
.current_date{font-weight:400;font-size:30px}
.page-sidebar .page-sidebar-menu{list-style:none;margin:0;padding:0}
.page-sidebar.navbar-collapse{padding:0;box-shadow:none}
.page-sidebar .page-sidebar-menu{list-style:none;margin:0;padding:0}
.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:before,.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:after,.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:before,.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:after{content:" ";display:table}
.page-sidebar .page-sidebar-menu>li.sidebar-toggler-wrapper:after,.page-sidebar .page-sidebar-menu>li.sidebar-search-wrapper:after{clear:both}
.page-sidebar .page-sidebar-menu>li>a{display:block;position:relative;margin:0;border:0;padding:10px 15px;text-decoration:none;font-size:14px;font-weight:400}
.page-sidebar .page-sidebar-menu>li>a>i{font-size:16px;margin-right:5px;text-shadow:none}
.page-sidebar .page-sidebar-menu>li.active>a>.selected{display:block;background-image:none;float:right;position:absolute;right:0;top:16px;background:0;width:0;height:0;border-top:12px solid transparent;border-bottom:12px solid transparent;border-right:12px solid white}
.page-sidebar .page-sidebar-menu li>a>.arrow:before{float:right;width:10px;text-align:center;margin-top:4px;margin-right:5px;margin-left:5px;display:inline;font-size:20px;font-family:FontAwesome;height:auto;content:"\f104";font-weight:400;text-shadow:none}
.page-sidebar .page-sidebar-menu li>a>.arrow.open:before{content:"\f107";font-size:20px}
.page-sidebar .page-sidebar-menu .sub-menu{list-style:none;display:none;padding:0;margin:8px 0 8px 0}
.page-sidebar .page-sidebar-menu .sub-menu li>a{display:block;margin:0;padding:6px 15px 6px 43px;text-decoration:none;font-size:14px;font-weight:400;background:0}
.page-sidebar .page-sidebar-menu li.active>.sub-menu{display:block}
.page-sidebar .sidebar-toggler{display:block;cursor:pointer;opacity:.7;filter:alpha(opacity=70);width:30px;height:27px;margin-top:15px;margin-right:19px;float:right;border-radius:4px}
.page-sidebar .page-sidebar-menu>li>a{border-top:1px solid #484848;color:#5b5b5b;font-family:'Open Sans',sans-serif;font-weight:400;font-size:24px}
.page-sidebar .page-sidebar-menu>li:last-of-type>a{border-bottom:1px solid #484848 !important}
.page-sidebar .page-sidebar-menu>li>a>i{color:#5b5b5b;font-size:20px}
.page-sidebar .page-sidebar-menu>li:hover>a,.page-sidebar .page-sidebar-menu>li.open>a{background:#b5b5b5;color:#5b5b5b}
.page-sidebar .page-sidebar-menu>li.active>a,.page-sidebar .page-sidebar-menu>li.active.open>a{background:#00a0d6;border-top-color:transparent;color:white;font-family:'Open Sans',sans-serif;font-weight:400;font-size:24px}
.page-sidebar .page-sidebar-menu>li.active>a>i,.page-sidebar .page-sidebar-menu>li.active.open>a>i{color:white}
.page-sidebar .page-sidebar-menu>li.active+li>a{border-top-color:transparent}
.page-sidebar .page-sidebar-menu>li.active.open+li>a{border-top-color:#484848}
.page-sidebar .page-sidebar-menu .sub-menu>li>a{color:#5b5b5b;font-family:'Open Sans',sans-serif;font-weight:400;font-size:20px;-webkit-transform-style:preserve-3d}
.page-sidebar .page-sidebar-menu .sub-menu>li:hover>a,.page-sidebar .page-sidebar-menu .sub-menu>li.open>a,.page-sidebar .page-sidebar-menu .sub-menu>li.active>a{background:#b5b5b5 !important}
.page-content-wrapper{float:left;width:100%}
.page-content-wrapper .page-content{margin-left:115px;margin-top:0;min-height:600px;padding:10px 20px 10px 20px}
.page-content-wrapper .page-content.no-min-height{min-height:auto}
.page-footer{padding:8px 20px 5px 20px;font-size:12px;height:33px}
.page-footer:before,.page-footer:after{content:" ";display:table}
.page-footer:after{clear:both}
.page-footer .page-footer-inner{float:left;display:inline-block}
.page-footer-fixed.page-footer-fixed-mobile .page-footer{position:fixed;left:0;right:0;z-index:10000;bottom:0}
.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer{margin-left:0 !important}
.scroll-to-top{display:inline-block;padding:1px;text-align:center;position:fixed;bottom:10px;z-index:10001;display:none;right:10px}
.scroll-to-top>i{display:inline-block;color:#687991;font-size:30px;opacity:.6;filter:alpha(opacity=60)}
.scroll-to-top:hover{cursor:pointer}
.scroll-to-top:hover>i{opacity:1;filter:alpha(opacity=100)}
.page-footer .page-footer-inner{color:#a3a3a3}
.page-footer-fixed .page-footer{background-color:#2b2b2b}
.page-sidebar-fixed:not(.page-footer-fixed) .page-content{border-bottom:0}
.page-sidebar-fixed:not(.page-footer-fixed) .page-footer{background-color:#fff}
.page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner{color:#333}
.form-control::-moz-placeholder{color:#b3b3b3;opacity:1}
.form-control:-ms-input-placeholder{color:#b3b3b3}
.form-control::-webkit-input-placeholder{color:#b3b3b3}
.button{margin:0;height:34px;background:#00a0d6;color:white;border-radius:4px;cursor:pointer;outline:0;border:0;padding-top:4px;text-align:center;margin-bottom:15px;font-weight:400;font-size:18px;line-height:25px;padding-left:10px;padding-right:10px;padding-top:0 !important}
.button.gray{background:#ebebeb;color:black}
.content_box{width:100%;border-radius:10px;background-color:white;margin-bottom:20px}
.content_box.empty{background-color:transparent}
.content_box_header{background:#d6d6d6;width:100%;height:50px;padding-left:15px;padding-top:8px;color:#5b5b5b;font-family:'Open Sans',sans-serif !important;font-size:24px;font-weight:400;border-top-left-radius:10px;border-top-right-radius:10px}
.content_box_content{padding:15px;width:100%;font-size:16px}
.form_left{position:relative;float:left !important}
.add_button{width:100%;position:relative;margin-bottom:10px;text-align:right;padding-right:10px}
.content_box_sort{width:5%;position:relative;float:left;text-align:left;padding-right:10px}
.conten_box_title{width:95%;position:relative;float:left}
.content_box_trash{width:5%;position:relative;float:left;text-align:right;padding-right:10px}
.edit_question{cursor:text}
.edit_answer{cursor:text}
.btn-file{position:relative;overflow:hidden}
.btn-file input[type=file]{position:absolute;top:0;right:0;min-width:100%;min-height:100%;font-size:999px;text-align:right;filter:alpha(opacity=0);opacity:0;outline:0;background:none repeat scroll 0 0 #007bff;cursor:inherit;display:block;border:1px solid #333}
.current_time_div{font-family:'Open Sans',sans-serif !important;text-align:center;margin-top:27px}
.current_time{font-size:58px;font-weight:200;line-height:52px}
.current_date{font-weight:400;font-size:30px}
.content_box{width:100%;position:relative;background:white;border-radius:10px;max-width:1212px;margin:auto;margin-bottom:26px}
.content_box .title,.content_box .title_edit{width:100%;position:relative;background-color:#d6d6d6;color:#2c2c2c;border-top-left-radius:10px;border-top-right-radius:10px;height:34px;padding-left:30px;font-weight:400;font-family:'Open Sans',sans-serif !important;font-size:21px}
.content_box .title.only{border-bottom-left-radius:10px;border-bottom-right-radius:10px}
.content_box .content{width:100%;position:relative;font-weight:400;font-family:'Open Sans',sans-serif !important;font-size:21px;padding-top:12px;padding-bottom:12px;padding-left:25px;padding-right:25px;color:#7a7a7a}
.clock_div{padding-top:26px;width:100%;text-align:center;margin:auto;height:206px}
.clock{font-family:'Open Sans',sans-serif !important;font-size:160px;font-weight:200;line-height:160px}
.icon_holder{background-color:#00a0d6;color:white;width:23px;height:23px;border-radius:3px;float:left;position:relative;line-height:20px;margin-top:3px;padding-top:1px;padding-left:1px;outline:0;border:0;text-align:center}
.icon_holder.add_button{background-color:#45b688;margin-left:10px;margin-top:5px;cursor:pointer}
.icon_holder.edit_button{background-color:#00a0d6;margin-left:10px;margin-top:5px;cursor:pointer}
.icon_holder.undo_button{background-color:#7d7d7d;margin-left:10px;margin-top:5px;cursor:pointer}
.icon_holder.cancel_button{color:#7d7d7d;margin-left:10px;cursor:pointer;background-color:white}
.icon_holder.accept_button{background-color:white;cursor:pointer;color:#00a0d6}
.icon_holder .fa{color:#00a0d6}
.content_title{font-size:58px;font-family:'Open Sans',sans-serif !important;font-weight:400;text-align:center;margin-bottom:15px}
.content_subtitle{font-size:32px;font-family:'Open Sans',sans-serif !important;font-weight:400;text-align:center;margin-bottom:15px}
.error{color:#00a0d6;font-size:18px}
.edit{cursor:pointer}
.group_title{font-family:'Open Sans',sans-serif !important;font-size:25px;font-weight:400;color:#333}
.form-error,.has-error{border:1px solid #db4c46 !important;border-top-right-radius:4px !important;border-bottom-right-radius:4px !important}
.export_statistics{position:absolute;right:25px}
.btn-export{background:#00a0d6;color:white;font-family:'Open Sans',sans-serif !important;font-weight:400;font-size:18px}
.btn-export .typcn-eye{font-size:21px}
.btn-export:hover,.btn-export:focus,.btn-export.focus,.btn-export:active,.btn-export.active,.open>.dropdown-toggle.btn-export{background:#00a0d6;color:white}
.export-menu{font-family:'Open Sans',sans-serif !important;font-weight:400;font-size:18px;background:#00a0d6;top:35px}
.export-menu>li>a{color:white}
.export-menu>li:hover>a{color:white;background:#00a0d6}
.add_protocol_category{position:relative;float:left;margin-top:37px}
.add_protocol{position:absolute;right:25px}
.download_protocol{position:relative;float:right}
.datepicker{z-index:9999 !important}
.back_button{position:absolute;left:255px}
.ui-autocomplete{z-index:9999}
.error_message{color:#00a0d6}
.fa.white{color:white;font-size:18px}
.input-icon .typcn{font-size:20px;line-height:10px}
.inputs_left{width:100%;position:relative;padding-right:130px}
.buttons_right{position:absolute;width:110px;right:15px;top:45px}
.buttons_right_bottom{position:absolute;width:110px;right:15px;bottom:35px}
.input_fields{padding-top:10px;position:relative;clear:both}
.input_fields.no-padding{padding-top:0}
.icon_label{font-family:'Open Sans',sans-serif !important;font-size:21px;color:#7a7a7a;float:left;position:relative;margin-left:5px}
.icon_holder .fa-sitemap{padding-left:2px}
.icon_holder .fa-pencil{padding-left:4px}
.icon_holder .fa-header{padding-left:3px}
.icon_holder .fa-expand{padding-left:2px}
.icon_holder .fa-picture-o{padding-left:2px}
.icon_holder .fa-industry{padding-left:3px}
.icon_holder .fa-truck{padding-left:2px}
.icon_holder .fa-database{padding-left:3px}
.icon_holder .fa-product-hunt{padding-left:3px}
.icon_holder .fa-paint-brush{padding-left:3px}
.icon_holder .fa-shopping-bag{padding-left:3px}
.icon_holder .fa-check-circle{padding-left:3px}
.icon_holder .fa-plus{padding-left:3px}
.icon_holder .fa-search{padding-left:3px}
.icon_holder .fa-folder-open{padding-left:3px}
.icon_holder .fa-list-ol{padding-left:2px}
.icon_holder .fa-globe{padding-left:3px}
.icon_holder .fa-user{padding-left:4px}
.icon_holder .fa-fire-extinguisher{padding-left:3px}
.icon_holder .fa-map-marker{padding-left:6px}
.icon_holder .fa-list-ol{padding-left:2px}
.icon_holder .typcn-upload{padding-left:2px}
.icon_holder .fa-youtube{padding-left:3px}
.icon_holder .fa-qrcode{padding-left:3px}
.icon_holder .fa-fire{padding-left:3px}
.icon_holder .fa-drupal{padding-left:3px}
.icon_holder .fa-scissors{padding-left:3px}
.icon_holder .fa-link{padding-left:2px}
.category_title td{color:#fff !important;background-color:#999}
.category_title td:first-of-type{text-align:center}
.button-array{margin:auto auto 26px;max-width:1212px;position:relative;width:100%;text-align:right}
.button-array button{width:auto}
.back_button{position:absolute;left:255px}
.page-bar{padding:0;margin-bottom:25px;border-radius:4px}
.page-bar:before,.page-bar:after{content:" ";display:table}
.page-bar:after{clear:both}
.page-bar .page-breadcrumb{display:inline-block;float:left;padding:8px;margin:0;list-style:none}
.page-bar .page-breadcrumb>li{display:inline-block}
.ie8 .page-bar .page-breadcrumb>li{margin-right:1px}
.page-bar .page-breadcrumb>li>a,.page-bar .page-breadcrumb>li>span{color:#444;font-size:16px;font-weight:400;font-family:'Open Sans',sans-serif !important;text-shadow:none}
.page-bar .page-breadcrumb>li>i{color:#444;font-size:16px;text-shadow:none}
.page-bar .page-breadcrumb>li>i[class^="icon-"],.page-bar .page-breadcrumb>li>i[class*="icon-"]{color:gray}
.page-breadcrumb>li>a{font-size:21px !important}
.page-breadcrumb>li>i{font-size:21px !important}
.sub_content_box{background:#f0f0f0;padding:10px;margin-bottom:10px;border-radius:3px}
.dragndroparea{color:white;background:#00a0d6;opacity:.5;height:60px;width:100%;border-radius:3px;padding:15px;margin-top:10px}
.thumb_td{width:40px !important}
.preview-thumb{width:40px;height:40px;margin-left:5px;margin-right:5px;cursor:pointer}
.ajax-spinner{background-image:url(../img/ajax-spinner.gif);width:32px;height:32px;position:relative;display:none}
.table_content th.center{text-align:center !important}
.bestellungs_filter{width:100%;padding-left:25px;padding-right:25px;margin-top:20px}
table.customer_table{font-size:18px}
table.customer_table td:first-child{min-width:150px;font-weight:bold}
.tbl-cart thead{background:#f8f8f8}
.tbl-cart .td-qty{white-space:normal !important;width:130px}
.btns-cart .btn{margin-right:3px}
.tbl-checkout tbody tr td:nth-child(odd){background:#f8f8f8}
.cart-image{max-height:50px;max-width:50px}
.tbl-cart{font-size:18px}
.tbl-cart td,.tbl-cart th{padding-left:5px !important}
.cart_variantes{color:#666;font-size:16px}
.sum_table{float:right;border-spacing:0;border-collapse:collapse;margin-bottom:30px;font-size:18px}
.sum_table td{border:1px solid silver;padding:5px;vertical-align:top;background-color:#f7f7f7;text-align:right}
.sum_table td:first-child{min-width:234px}
.sum_table td:last-child{min-width:150px}
.sum_table tr.price_sum{font-size:18px;font-weight:bold}
.bestellungs_filter{width:100%;padding-left:25px;padding-right:25px;margin-top:20px}
.artnr{margin-top:0;font-size:16px}
.form-error{border:1px solid #920404}
label{font-weight:normal !important}
.help-block{color:#db4c46}
.button-small>a>i{color:white}
.filtered{display:none}
.tablesorter-blue .header,.tablesorter-blue .tablesorter-header{background-image:url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);background-repeat:no-repeat;background-position:center right;padding:4px 18px 4px 4px;white-space:normal;cursor:pointer}
.tablesorter-blue .headerSortUp,.tablesorter-blue .tablesorter-headerSortUp,.tablesorter-blue .tablesorter-headerAsc{outline:0;background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7)}
.tablesorter-blue .headerSortDown,.tablesorter-blue .tablesorter-headerSortDown,.tablesorter-blue .tablesorter-headerDesc{outline:0;background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7)}
.tablesorter-blue thead .sorter-false{background-image:none;cursor:default;padding:4px}
.delayed_task_reason_counter{float:right;position:relative;text-align:right;margin-top:15px}
.delayed_task_reason_error{margin-top:15px;color:#d72222;position:relative;float:left}
.createchange_dependencies .divider{display:none !important}
div.has-error ul.select2-choices{border-color:#db4c46 !important}
.qq-upload-button-selector.qq-upload-button{background-color:#00a0d6;color:white;border-radius:5px;border:0;font-weight:400;font-size:16px;padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px;width:150px;box-shadow:none}
.qq-upload-delete{width:20px !important;height:20px !important;background-color:#00a0d6 !important;border-radius:3px !important;color:white !important;padding-left:4px !important;float:left !important;position:relative !important;cursor:pointer !important;border:none !important;text-shadow:none !important}
.qq-upload-cancel{width:20px !important;height:20px !important;background-color:#00a0d6 !important;border-radius:3px !important;color:white !important;padding-left:4px !important;float:left !important;position:relative !important;cursor:pointer !important;border:none !important;text-shadow:none !important}
.qq-progress-bar{background-color:#00a0d6 !important}
.qq-upload-list{padding-bottom:40px !important;box-shadow:none !important}
.tablesorter-filter.disabled{display:none}
.filterhidler tr>td:nth-of-type(7) input{display:none}
html,body{font-family:'Open Sans',sans-serif !important;width:100%;color:#323232;padding:0;margin:0;font-size:15px;direction:ltr;font-weight:400;background:#fff}
.login-content{width:100%;font-weight:400;text-align:center;font-size:20px;padding-top:100px}
.login-box{width:360px;background-color:#f1f1f1;padding:50px;border-radius:10px;margin:auto;margin-top:10px}
.login-box h1{font-weight:400;padding-bottom:30px;padding-top:0;margin-top:0;font-size:40px}
.button-standard{background-color:#00a0d6;color:white;border-radius:5px;border:0;font-weight:400;font-size:16px;padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px}
.input-group-addon{background-color:#00a0d6;color:white;border:1px solid #00a0d6;font-size:16px}
.smaller-input-group-addon{font-size:12px}
.login-logo{width:50px;height:50px;margin-top:-6px}
.button-fa{margin-top:4px;margin-right:4px}
.menu-logo{float:left;position:relative;font-weight:400;font-size:20px;margin-right:50px}
.menu-logo>img{width:30px;height:30px;margin-top:-6px}
.menu{background-color:#f1f1f1;font-weight:400;font-size:20px;width:100%;padding-top:10px;padding-bottom:10px;padding-left:50px;float:left;position:relative}
.menu-items{float:left;margin-bottom:0;cursor:pointer}
.menu-items>li{float:left;padding-right:20px;list-style-type:none;border-right:1px dotted black;padding-left:20px}
.menu-items>li:last-of-type{border-right:0}
.menu-items>li>span{border-bottom:0;-webkit-transition:.2s ease-in;transition:.2s ease-in;margin-left:18px}
.menu-items>li:hover>span,.menu-items>li:active>span{color:#00a0d6;border-bottom:5px solid #00a0d6}
.menu-user{float:right;margin-right:50px;cursor:pointer}
.user-avatar{width:30px;height:30px;border-radius:5px;margin-top:-5px}
.menu-icons{font-size:14px;position:absolute;top:17px}
.title{margin-left:30px;margin-top:30px;font-weight:400;font-size:30px;float:left}
.main-box{margin-left:30px;margin-right:30px;margin-top:10px;background-color:#f1f1f1;padding:20px;border-radius:10px;float:left;position:relative;width:calc(100% - 60px)}
.side-container:first-of-type .side-box{margin-right:0;width:100%}
.side-container:last-of-type .side-box{margin-left:0;width:calc(100% - 30px)}
.side-container{padding-right:0}
.ajax-spinner{background-image:url(../img/ajax-spinner.gif);width:32px;height:32px;position:relative;display:none;margin-bottom:20px}
h2{font-weight:400;font-size:22px;position:relative;margin-top:0;margin-left:5px;padding-bottom:20px}
.h2-icon{height:22px;width:22px;font-size:12px;background-color:#00a0d6;color:white;border-radius:3px;float:left;position:relative;padding-left:5px;padding-top:2px}
h4{margin-top:0;margin-left:5px}
.input-group{margin-bottom:10px}
.helptext{font-weight:400;margin-top:10px;color:#666;padding-bottom:20px}
.helpdivider{border-top:1px dotted #999}
.btn-primary.active,.btn-primary.active:hover{background-color:#00a0d6;border-color:#00a0d6}
.btn-primary{background:white;color:black;text-shadow:none;border-color:#00a0d6}
.btn-primary:hover{background-color:#00a0d6;border-color:#00a0d6}
.button-small{width:20px;height:20px;background-color:#00a0d6;border-radius:3px;color:white;padding-left:4px;float:left;position:relative;cursor:pointer}
.table>tbody>tr>td:last-of-type{width:20px}
.button-end{position:relative;float:right;margin-top:20px;margin-right:35px}
.table-facilities>tbody>tr>td:last-of-type{width:80px}
.table-departments>tbody>tr>td:last-of-type{width:80px}
.table-departments>tbody>tr>td:nth-of-type(2){width:80px;padding-right:20px}
.table-departments>tbody>tr>td:nth-of-type(3){width:80px;text-align:left;padding-right:20px;padding-left:20px}
.table-audits>tbody>tr>td:last-of-type{width:105px}
.factbox{font-weight:400;padding:10px;border:1px dashed #999;border-radius:5px;color:#666;padding-top:0}
h3{font-size:14px;font-weight:400;padding-bottom:2px;margin-bottom:0;padding-top:0;margin-top:10px}
.form-error{border:1px solid #00a0d6}
#content{margin-bottom:20px}
.icon_holder{background-color:#00a0d6;color:white;width:20px;height:20px;border-radius:3px;float:left;position:relative;line-height:20px;padding-top:1px;padding-left:2px;outline:0;border:0;text-align:center}
.icon_holder .fa{padding-left:3px}
.icon_holder .fa-close{padding-left:3px}
.icon_holder .fa-check{padding-left:1px}
.icon_holder.cancel_button{color:#7d7d7d;margin-left:10px;cursor:pointer;background-color:white}
.icon_holder.accept_button{background-color:white;cursor:pointer;color:#1c59af}
.delete_tr{display:none}
.delete_tr>td{background:#00a0d6}
.delete_confirmation{color:white;padding-left:10px;float:left;position:relative}
.delete_confirmation_buttons{position:relative;float:right;padding-right:0}
.alert-danger{color:#1c59af;border:0;background:0;font-size:14px;box-shadow:none}
.rolloverMenu{position:absolute;right:45px;top:37px;background:#f1f1f1;width:auto;z-index:1;font-size:14px;box-shadow:0 5px 5px 0 rgba(0,0,0,0.2)}
.rolloverMenu i{float:left}
.rolloverMenu>ul{margin-left:0;padding-left:0;padding-top:20px}
.rolloverMenu>ul>li{list-style-type:none;cursor:pointer;padding-right:20px;padding-bottom:8px;margin-left:20px;padding-left:0}
.rolloverMenu>ul>li>a,.rolloverMenu>ul>li>a:hover,.rolloverMenu>ul>li>a:visited{text-decoration:none;color:black}
.rolloverMenu>ul>li:last-of-type{border-right:0}
.rolloverMenu>ul>li>a>span{border-bottom:0;-webkit-transition:.2s ease-in;transition:.2s ease-in;margin-left:18px}
.rolloverMenu>ul>li:hover>a>span,.menu-items>li:active>a>span{color:#00a0d6;border-bottom:5px solid #00a0d6}
.rolloverMenu>ul>li>a>i{margin-top:4px}
.form-error-div{color:#00a0d6;margin-top:0;margin-bottom:10px}
.header .logo{font-family:'Alegreya Sans',sans-serif;color:#5b5b5b;font-size:24px;letter-spacing:7px;text-transform:uppercase;margin-left:20px;position:absolute;top:10px;background-size:100px 17px;width:100px;height:17px;margin-top:4px}
.tree-box{width:100%;float:left;position:relative;overflow-y:auto}
.jstree-icon.fa.fa-user{color:#00a0d6}
.table-inline-input{width:100%;border:1px solid #ccc;border-radius:2px;background-color:transparent;text-align:center}
.single-button{float:right}
.lastler{text-align:right}
.date-catastrophe{color:#920404}
.date-warning{color:#00a0d6}
.formcontinue{display:none}
.tree-box-button-container{width:100%;float:left;position:relative;padding-top:20px}
.buttonhalf{width:calc(50% - 5px);float:left}
.buttonhalf:first-of-type{padding-right:5px}
.buttonhalf:nth-of-type(2){padding-left:5px;float:right}
.buttonhalf>button{padding-left:10px;padding-right:10px;font-size:12px;width:100%;text-align:center}
.delete_warning{width:100%;float:left;position:relative;padding-top:20px;background-color:#bd3e3e;font-size:12px;color:white;border-radius:5px;padding:10px;margin-top:20px;text-align:center}
.delete_warning button{background-color:white;color:#333}
.confirm-box-button-container{width:100%;float:left;position:relative;padding-top:20px}
.full-screen-menuitems{width:900px;float:left;position:relative}
.full-screen-menuitems .input-group{margin-bottom:0 !important}
.page-content-fullscreen{margin:15px !important;padding:0}
.page-footer-fullscreen{margin-left:15px !important}
.table-employeelist>tbody>tr>td:first-of-type{width:20px}
hr{border-top:1px dashed #ccc}
.dragndroparea{text-align:center;font-size:20px;margin-top:0;margin-bottom:20px}
.table-files>tbody>tr>td:last-of-type{padding-right:5px;width:55px !important}
.table-files>thead>tr>th:last-of-type{text-align:right}
.helpgurble{min-height:200px}
.helpgurble li{margin-bottom:10px}
.help-container .main-box{width:calc(100% - 30px);margin-right:0}
.help-container{padding-right:0}
.help-container:nth-of-type(2) .main-box{margin-left:0}
.belp-container .main-box{width:calc(100% - 40px)}
.jstree-node .fa-star{color:#00a0d6}
.jstree-node .fa-eye{color:#00a0d6}
#qualifications table>tbody>tr>td:nth-of-type(2){width:150px}
#employeesheetqualifications table>tbody>tr>td:last-of-type,#employeesheetqualifications table>thead>tr>th:last-of-type{display:none}
#EmployeeEmailNotificationsForm table>tbody>tr>td:last-of-type,#EmployeeEmailNotificationsForm table>thead>tr>th:last-of-type{display:none}
#EmployeeEmailNotificationsForm table>tbody>tr>td:nth-of-type(6),#EmployeeEmailNotificationsForm table>thead>tr>th:nth-of-type(6){width:150px}
.select2-selection__rendered{max-width:100%;overflow-x:hidden}
.input-group{max-width:100%}
.select2{max-width:100%}
.form-group .select2-container{position:relative !important;z-index:2 !important;float:left !important;width:100% !important;margin-bottom:0 !important;display:table !important;table-layout:fixed !important}
#qualifications_table table>tbody>tr>td:nth-of-type(4){width:100px;text-align:right}
.stretch{margin-left:0 !important;width:100vw}
.copyright{font-size:12px;margin-top:20px}
.status-inner{width:10px;height:10px;border-radius:10px}
.status-outer{width:20px;height:20px;border-radius:10px;background-color:white;padding-top:5px;padding-left:5px;display:inline-block;vertical-align:middle;margin-right:5px}
.critical{background-color:#d72222}
.ontime{background-color:#64b445}
.warning{background-color:#ff7f55}
.done{background-color:#00a0d6}
.text-critical{color:#d72222}
.text-ontime{color:#64b445}
.text-warning{color:#ff7f55}
.text-done{color:#00a0d6}
.text-rejected{color:#999}
.rejected{background-color:#999}
.mytasks-ul,.mytasks-ul li{list-style-type:none;margin-left:0;padding-left:0}
.task-behind{color:#d72222}
.status-outer.darker{background-color:#dedede}
.form-label{font-weight:400 !important;padding-left:5px}
.checkbox-group{padding-top:33px}
textarea{width:100%;resize:none}
.timeline{width:50px;position:relative;float:left;padding-left:20px;margin-top:50px;margin-bottom:100px}
.line-inner{width:20px;background-color:white;height:100%}
.bobbel{width:40px;height:40px;border-radius:20px;background-color:white;position:relative;cursor:pointer}
.bobbel-inner{top:7px;left:7px;width:26px;height:26px;border-radius:26px;position:absolute}
.attention{border-radius:0}
.line-overlay{position:absolute;height:100%;width:40px;top:-5px;left:10px}
.billet{background-color:white;border-radius:10px;padding:15px;position:absolute;left:80px;top:50%;width:250px;-ms-transform:translateY(-50%);transform:translateY(-50%);-webkit-transform:translateY(-50%);display:none;z-index:999;box-shadow:0 0 10px 0 rgba(0,0,0,0.3)}
.bobble:hover+.billet{display:block}
.active-pointer{-ms-transform:translateY(-50%);transform:translateY(-50%);-webkit-transform:translateY(-50%);position:absolute;left:80px;top:50%}
.billet:after,.billet:before,.active-pointer:after,.active-pointer:before{right:99%;top:50%;border:solid transparent;content:" ";height:0;width:0;position:absolute;pointer-events:none}
.billet:after,.active-pointer:after{border-color:rgba(255,255,255,0);border-right-color:#fffffffffffffffffff;border-width:20px;margin-top:-20px}
.billet:before,.active-pointer:before{border-color:rgba(255,255,255,0);border-right-color:#fff;border-width:23px;margin-top:-23px}
h3{font-weight:400;font-size:24px;float:left;border-bottom:1px dotted black;position:relative;margin-top:20px;margin-left:5px;margin-bottom:20px}
.divider{height:1px;background-color:#ddd;width:calc(100% - 40px);margin-left:20px;margin:20px;margin-top:20px}
.pillepalle{position:relative;width:100%;float:left;height:38px}
.connector-vertical{width:1px;height:20px;position:absolute;left:10px;top:0;border-left:1px dotted red;background-color:transparent !important}
.connector-horizontal{height:1px;width:20px;position:absolute;left:10px;top:20px;border-bottom:1px dotted red;background-color:transparent !important}
.pille{border-radius:4px;background-color:red;color:white;height:30px;width:auto;position:absolute;left:30px;top:5px;padding-left:10px;padding-right:10px;padding-top:4px;border:none !important}
.tool *{background-color:#00a0d6;border-color:#00a0d6}
.article *{background-color:#ababab;border-color:#ababab}
.third-level *{background-color:#5b5b5b;border-color:#5b5b5b}
.conflict>*{background-color:#d72222;border-color:#d72222}
.warning-curtain{position:fixed;width:100vw;height:100vh;background-color:rgba(0,0,0,0.3);z-index:998;top:0;left:0}
.warning-overlay{width:80%;top:100px;left:50%;webkit-transform:translateX(-50%);-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);padding-bottom:20px;padding-left:20px;padding-top:10px;padding-right:20px;position:fixed;z-index:999;background-color:white;border-radius:10px;box-shadow:0 0 10px 0 rgba(0,0,0,0.3)}
.warning-title{margin-left:-20px;margin-right:-20px;margin-top:-10px;width:calc(100% + 40px);background-color:#f1f1f1;padding:5px;border-top-left-radius:10px;border-top-right-radius:10px;margin-bottom:20px}
.warning-body{width:100%}
.warning-sign{float:left;position:relative;width:128px;height:116px;background-image:url("../img/waarschuwing-red.svg");background-size:128px 116px;margin-bottom:20px}
.warning-text{float:left;width:calc(100% - 128px);padding-left:20px;margin-bottom:20px}
.warning-shouty{font-size:24px}
.tablesorter-filter{height:34px;padding:6px 12px;font-size:14px;line-height:1.42857143;color:#555;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:4px;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-webkit-transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s}
.norm-control{display:block;width:100%;padding:6px 12px;font-size:14px;line-height:1.42857143;color:#555;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:4px;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-webkit-transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s}
.previous_revision_change{margin-left:15px;margin-bottom:15px;font-family:"Courier New","Courier",sans-serif}
.previous_revision_change .previous_value{color:#910008;text-decoration:line-through}
ins{color:green;text-decoration:none}
del{color:#910008;text-decoration:line-through}
.btn.focus,.btn:focus,.btn:hover{color:white}
.mytasks-ul a,.mytasks-ul a:hover,.mytasks-ul a:visited{color:black;text-decoration:none}
.input-group-addon .fa{font-size:20px}
.product_seperator{height:40px;width:100%}
.table-editable{position:relative}
.table-editable .glyphicon{font-size:20px}
.table-remove{color:#700;cursor:pointer}
.table-remove:hover{color:red}
.table-up,.table-down{color:#007;cursor:pointer}
.table-up:hover,.table-down:hover{color:#00f}
.table-add{color:#070;cursor:pointer;position:absolute;top:8px;right:0}
.table-add:hover{color:#0b0}
@media(min-width:992px){.page-footer{clear:left}
.page-footer-fixed .page-footer{position:fixed;left:0;right:0;z-index:10000;bottom:0}
.page-sidebar-fixed.page-sidebar-closed .page-footer{margin-left:45px}
.page-sidebar-fixed.page-footer-fixed .page-footer{margin-left:0 !important}
.page-sidebar-fixed .page-footer{margin-left:235px;padding:8px 20px 5px 20px}
.page-boxed .page-footer{padding:8px 0 5px 0}
.page-boxed.page-sidebar-fixed .page-footer{padding-right:20px;padding-left:20px}
.page-sidebar-reversed.page-sidebar-fixed .page-footer{margin-left:0;margin-right:235px;padding:8px 20px 5px 20px}
.page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer{margin-left:0;margin-right:0}
.page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer{margin-right:45px}
.scroll-to-top{right:20px}
}@media(max-width:995px){.back_button{display:none}
.back_button{display:none}
}@media(max-width:991px){.page-boxed .page-footer{padding-left:0;padding-right:0}
.scroll-to-top{bottom:10px;right:10px}
.scroll-to-top>i{font-size:28px}
.page-sidebar-wrapper{display:none}
.page-content-wrapper .page-content{margin:0 !important;padding:10px 10px 10px 10px !important;min-height:280px}
.inputs_left{width:100%;position:relative;padding-right:0}
.buttons_right{position:relative;width:100%;right:0;top:0}
.content_title{font-size:32px}
.content_subtitle{font-size:20px}
.top-menu{margin-right:20px !important;display:none !important}
.page-header{position:fixed !important}
.page-container{margin-top:47px !important}
.nav-mobile{position:fixed;top:0;right:-200px;width:200px;height:100%;background-color:rgba(0,0,0,0.8);z-index:9999 !important;overflow-y:auto;overflow-x:hidden;display:block;min-height:100%;-webkit-backface-visibility:hidden}
.show_mobile_nav{transform:translateX(-200px);-webkit-transform:translateX(-200px);-moz-transform:translateX(-200px);-ms-transform:translateX(-200px);-o-transform:translateX(-200px);-webkit-transition:all .5s ease 0s;transition:all .5s ease 0s;-webkit-backface-visibility:hidden}
.hide_mobile_nav{transform:translateX(0);-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);-webkit-transition:all .5s ease 0s;transition:all .5s ease 0s}
.menu-icon-mobile{background:url(../img/menu_default_dark.svg);width:24px;height:16px;background-size:24px 16px;right:20px;top:17px;z-index:99;position:absolute;cursor:pointer;-webkit-transition:opacity .5s ease-in-out;transition:opacity .5s ease-in-out;display:block}
.closeMenu{background:url(../img/menu_expanded_dark.svg);width:16px;height:16px;position:absolute;background-size:16px 16px;right:24px;top:17px;cursor:pointer;-webkit-transition:opacity .5s ease-in-out;transition:opacity .5s ease-in-out;opacity:0;display:none;z-index:999}
.nav-mobile .menu-container{background:white;position:absolute;left:0;width:100%;top:0;height:100%;background:#d6d6d6;display:block;min-height:100%}
.nav-mobile .menu-container ul.mobile-menu{list-style:none;margin:0;padding:0;width:100%}
.nav-mobile .menu-container ul.mobile-menu>li{width:100%;height:47px;border-bottom:1px solid #dfdfdf;padding-left:25px;padding-top:10px;background:#d6d6d6}
.nav-mobile .menu-container ul.mobile-menu>li>a{font-family:'Open Sans',sans-serif !important;font-size:19px;font-weight:400;color:#767676;text-transform:uppercase;text-decoration:none !important;border-bottom:none !important}
.nav-mobile .menu-container ul.mobile-menu>li>a:hover,.nav-mobile .menu-container ul.mobile-menu>li>a.active{color:#2a2a2a;text-decoration:none !important;border-bottom:none !important}
.page-breadcrumb>li>a{font-size:16px !important}
.page-breadcrumb>li>i{font-size:16px !important}
.page-bar{margin-top:-10px}
.page-breadcrumb>li>a{font-size:16px !important}
.page-breadcrumb>li>i{font-size:16px !important}
}@media(max-width:768px){.navbar-nav{margin:0}
.nav{padding-left:0;margin-bottom:0;list-style:none}
.navbar-nav>li>a{height:50px;padding-top:15px}
}@media(max-width:767px){.page-footer,.page-boxed .page-footer{padding-left:10px;padding-right:10px}
.page-footer-fixed .page-footer .container{padding-left:0;padding-right:0}
.button{width:100%}
.header .logo{letter-spacing:5px}
}@media(max-width:676px){.clock{font-size:80px;line-height:80px}
.clock_div{height:113px;padding-top:10px}
}
