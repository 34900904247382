html, body {
  font-family: 'Open Sans', sans-serif !important;
  width: 100%;
  color: #323232;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  direction: ltr;
  font-weight: 400;
  background: #ffffff;
}

.login-content {
  width: 100%;
  font-weight: 400;
  text-align: center;
  font-size: 20px;
  padding-top:100px;

}
.login-content img {
    width: 236px;
    display: none;
}
.login-box {
  width: 360px;
  background-color: #F1F1F1;
  padding: 50px;
  border-radius: 10px;
  margin: auto;
  margin-top: 10px;
}
.login-box h1 {
  font-weight: 400;
  padding-bottom: 30px;
  padding-top: 0px;
  margin-top:0px;
  font-size: 40px;
}
.button-standard {
  background-color: $maincolor;
  color:white;
  border-radius: 5px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  padding-top:5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.input-group-addon {
  background-color: $maincolor;
  color:white;
  border: 1px solid  $maincolor;
  font-size: 16px;
}

.smaller-input-group-addon {
  font-size: 12px;
}

.login-logo {
  width:50px;
  height:50px;
  margin-top:-6px;
}

.button-fa {
  margin-top:4px;
  margin-right:4px;
}

.menu-logo {
  float: left;
  position: relative;
  font-weight: 400;
  font-size: 20px;
  margin-right: 50px;
}

.menu-logo > img  {

  width: 30px;
  height: 30px;
  margin-top: -6px;
}

.menu {
  background-color: #F1F1F1;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  padding-left: 50px;
  float: left;
  position: relative;
}

.menu-items {
  float: left;
  margin-bottom: 0;
  cursor: pointer;
}

.menu-items > li {
  float: left;
  padding-right: 20px;
  list-style-type: none;
  border-right: 1px dotted black;
  padding-left: 20px;


}
.menu-items > li:last-of-type {
  border-right: none;

}

.menu-items > li > span {
  border-bottom: none;
  transition: 0.2s ease-in;
  margin-left:18px;


}


.menu-items > li:hover > span, .menu-items > li:active > span {
  color: $maincolor;
  border-bottom: 5px solid $maincolor;

}

.menu-user {
  float: right;
  margin-right: 50px;
  cursor: pointer;
}

.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-top: -5px;
}
.menu-icons {
  font-size: 14px;
  position:absolute;
  top: 17px;
}
.title {
  margin-left:30px;
  margin-top: 30px;
  font-weight: 400;
  font-size: 30px;
  float: left;
}
.main-box {
  margin-left:30px;
  margin-right: 30px;
  margin-top: 10px;
  background-color: #F1F1F1;
  padding: 20px;
  border-radius: 10px;
  float: left;
  position: relative;
  width: calc(100% - 60px);
}
.side-container:first-of-type .side-box {
  margin-right: 0px;
  width: 100%;
}
.side-container:last-of-type .side-box {
  margin-left: 0px;
  width: calc(100% - 30px);
}
.side-container {
  padding-right: 0;
}

.ajax-spinner {
  background-image: url(../img/ajax-spinner.gif);
  width: 32px;
  height: 32px;
  position: relative;
  display: none;
  margin-bottom: 20px;
}

h2 {
  font-weight: 400;
  font-size: 22px;
  /*  float: left;
  */  position: relative;
  margin-top: 0;
  margin-left: 5px;
  padding-bottom:20px;
}

.h2-icon {
  height: 22px;
  width: 22px;
  font-size: 12px;
  background-color: $maincolor;
  color:white;
  border-radius: 3px;
  float: left;
  position: relative;
  padding-left:5px;
  padding-top:2px;
}

h4 {
  margin-top: 0;
  margin-left: 5px;
}

.input-group {
  margin-bottom: 10px;
}
.helptext {
  font-weight: 400;
  margin-top:10px;
  color: #666;
  padding-bottom: 20px;
}
.helpdivider {
  border-top: 1px dotted #999;
}
.btn-primary.active, .btn-primary.active:hover {
  background-color: $maincolor;
  border-color: $maincolor;
}
.btn-primary  {
  background: white;
  color: black;
  text-shadow: none;
  border-color: $maincolor;
}
.btn-primary:hover  {
  background-color: $maincolor;
  border-color: $maincolor;
}
.button-small {
  width:20px;
  height:20px;
  background-color: $maincolor;
  border-radius: 3px;
  color:white;
  padding-left:4px;
  float: left;
  position: relative;
  cursor: pointer;
}
.table > tbody > tr > td:last-of-type {
  width: 20px;
}
.button-end {
  position: relative;
  float: right;
  margin-top: 20px;
  margin-right: 35px;
}
.table-facilities  > tbody > tr > td:last-of-type {
  width: 80px;
}
.table-departments  > tbody > tr > td:last-of-type {
  width: 80px;
}
.table-departments  > tbody > tr > td:nth-of-type(2) {
  width: 80px;
  padding-right: 20px;
}
.table-departments  > tbody > tr > td:nth-of-type(3) {
  width: 80px;
  text-align: left;
  padding-right: 20px;
  padding-left: 20px;
}
.table-audits  > tbody > tr > td:last-of-type {
  width: 105px;
}
.factbox {
  font-weight: 400;
  padding:10px;
  border: 1px dashed #999;
  border-radius: 5px;
  color: #666;
  padding-top: 0px;

}
h3 {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 2px;
  margin-bottom: 0px;
  padding-top: 0px;
  margin-top: 10px;
}

.form-error {
  border: 1px solid $maincolor;
}

#content {
  margin-bottom: 20px;
}

.icon_holder {
  background-color: $maincolor;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  float: left;
  position: relative;
  line-height: 20px;

  padding-top: 1px;
  padding-left: 2px;
  outline: 0;
  border: none;
  text-align: center;
}

.icon_holder .fa {
  padding-left: 3px;
}

.icon_holder .fa-close {
  padding-left: 3px;
}

.icon_holder .fa-check {
  padding-left: 1px;
}

.icon_holder.cancel_button {
  color: #7d7d7d;
  margin-left: 10px;
  cursor:pointer;
  background-color: white;

}

.icon_holder.accept_button {
  background-color: white;
  cursor:pointer;
  color: #1C59AF;
}

.delete_tr {
  display: none;
}

.delete_tr > td{
  background: $maincolor;

}

.delete_confirmation {
  color:  white;
  padding-left: 10px;
  float: left;
  position: relative;
}

.delete_confirmation_buttons {
  position: relative;
  float: right;
  padding-right: 0px;
}

.alert-danger {
  color: #1C59AF;
  border: none; background: none;
  font-size: 14px;
  box-shadow: none;

}

.rolloverMenu {
  position: absolute;
  right: 45px;
  top: 37px;
  background: #F1F1F1;
  width: auto;
  z-index: 1;
  font-size: 14px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.rolloverMenu i
{
  float: left;
}
.rolloverMenu > ul
{

  margin-left: 0;
  padding-left: 0;
  padding-top: 20px;
}
.rolloverMenu > ul > li
{
  list-style-type: none;
  cursor: pointer;
  padding-right: 20px;
  padding-bottom: 8px;
  margin-left: 20px;
  padding-left: 0;
}

.rolloverMenu > ul > li > a, .rolloverMenu > ul > li > a:hover, .rolloverMenu > ul > li > a:visited
{
  text-decoration: none;
  color: black;
}

.rolloverMenu > ul > li:last-of-type
{
  border-right: none;
}

.rolloverMenu > ul > li > a > span
{
  border-bottom: none;
  transition: 0.2s ease-in;
  margin-left:18px;
}


.rolloverMenu > ul > li:hover > a > span, .menu-items > li:active > a > span
{
  color: $maincolor;
  border-bottom: 5px solid $maincolor;
}

.rolloverMenu > ul > li > a > i {
  margin-top: 4px;
}

.form-error-div {
  color: $maincolor;
  margin-top: 0px;
  margin-bottom: 10px;
}

.header .logo {
  font-family: 'Alegreya Sans', sans-serif;
  color: #5b5b5b;
  font-size: 24px;
  letter-spacing: 7px;
  text-transform: uppercase;
  margin-left: 20px;
  position: absolute;
  top: 10px;
  background: url(../img/logo.png);
  background-size: 100px 17px;
  width: 100px;
  height: 17px;
  margin-top: 4px;
}
.tree-box {
  width: 100%;
  float: left;
  position: relative;
  overflow-y: auto;
}
.jstree-icon.fa.fa-user {
  color: $maincolor;
}
.table-inline-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: transparent;
  text-align: center;

}
.single-button {
  float: right;
}
.lastler {
  text-align: right;
}
.date-catastrophe {
  color: #920404;
}
.date-warning {
  color: $maincolor;
}
.formcontinue {
  display:none;
}
.tree-box-button-container {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 20px;
}
.buttonhalf {
  width: calc(50% - 5px);
  float: left;

}
.buttonhalf:first-of-type {
  padding-right: 5px;

}
.buttonhalf:nth-of-type(2) {
  padding-left: 5px;
  float: right;
}

.buttonhalf > button {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: 100%;
  text-align: center;

}

.delete_warning {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 20px;
  background-color: #BD3E3E;
  font-size: 12px;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
}

.delete_warning button {
  background-color: white;
  color: #333;
}
.confirm-box-button-container {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 20px;
}
.full-screen-menuitems {
  width: 900px;
  float: left;
  position: relative;
}

.full-screen-menuitems .input-group {
  margin-bottom: 0 !important;
}
.page-content-fullscreen {
  margin: 15px !important;
  padding: 0;
}
.page-footer-fullscreen {
  margin-left: 15px !important;
}
.table-employeelist > tbody > tr >td:first-of-type {
  width: 20px;
}
hr {
  border-top: 1px dashed #ccc;
}
.dragndroparea {
  text-align: center;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 20px;
}
.table-files > tbody > tr > td:last-of-type {
  padding-right: 5px;
  width: 55px !important;
}
.table-files > thead > tr > th:last-of-type {
  text-align:   right;
}

.helpgurble {
  min-height: 200px;
}

.helpgurble li {
  margin-bottom: 10px;
}

.help-container .main-box {
  width: calc(100% - 30px);
  margin-right: 0;}
.help-container {
  padding-right: 0;
}

.help-container:nth-of-type(2) .main-box {
  margin-left: 0;
}
.belp-container .main-box  {
  width: calc(100% - 40px);
}

.jstree-node .fa-star {
  color: $maincolor;
}
.jstree-node .fa-eye {
  color: $maincolor;
}

#qualifications table > tbody > tr > td:nth-of-type(2) {
  width: 150px;


}
#employeesheetqualifications table > tbody > tr > td:last-of-type, #employeesheetqualifications table > thead  > tr > th:last-of-type {
  display: none;
}

#EmployeeEmailNotificationsForm table > tbody > tr > td:last-of-type, #EmployeeEmailNotificationsForm table > thead  > tr > th:last-of-type {
  display: none;
}

#EmployeeEmailNotificationsForm table > tbody > tr > td:nth-of-type(6), #EmployeeEmailNotificationsForm table > thead  > tr > th:nth-of-type(6) {
  width: 150px;
}
.select2-selection__rendered {
  max-width: 100%;
  overflow-x:hidden;
}
.input-group {
  max-width: 100%;
}
.select2{
  max-width: 100%;
}

.form-group .select2-container  {
  position: relative !important;
  z-index: 2 !important;
  float: left !important;
  width: 100% !important;
  margin-bottom: 0 !important;
  display: table !important;
  table-layout: fixed !important;
}
#qualifications_table table > tbody > tr > td:nth-of-type(4) {
  width: 100px;
  text-align: right;
}
.stretch {
  margin-left: 0 !important;
  width: 100vw;
}
.copyright {
  font-size: 12px;
  margin-top: 20px;
}
.status-inner {
  width: 10px;
  height: 10px;
  border-radius: 10px;


}
.status-outer {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  padding-top: 5px;
  padding-left: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;

}
.critical {
  background-color: #D72222;
}
.ontime {
  background-color: #64B445;
}
.warning {
  background-color: #FF7F55;
}
.done {
  background-color: $maincolor;
}

.text-critical {
  color: #D72222;
}
.text-ontime {
  color: #64B445;
}

.text-warning {
  color: #FF7F55;
}
.text-done {
  color: $maincolor;
}
.text-rejected {
  color: #999;
}


.rejected {
  background-color: #999;
}
.mytasks-ul , .mytasks-ul li {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.task-behind {
  color: #D72222;
}

.status-outer.darker {
  background-color: #dedede;

}
.form-label {
  font-weight: 400 !important;
  padding-left: 5px;
}
.checkbox-group {
  padding-top: 33px;
}
textarea {
  width: 100%;
  /*height: 150px;*/
  resize: none;
}
.timeline {
  width: 50px;
  position: relative;
  float: left;
  padding-left: 20px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.line-inner {
  width: 20px;
  background-color: white;
  height: 100%;

}
.bobbel {

  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  position: relative;
  cursor: pointer;
}

.bobbel-inner {
  top: 7px;
  left: 7px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  position: absolute;

}
.attention {
  border-radius: 0;
  /*  background-color: transparent;*/
}
/*.bobbel.attention{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 40px 20px;
  border-color: transparent transparent #ffffff transparent;


}

.bobbel-inner.attention {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 13px 26px 13px;
  border-color: transparent transparent #D72222 transparent;
  transform: translate(-20px, 4px);

}
*/
.line-overlay {
  position: absolute;
  height: 100%;
  width: 40px;
  top:-5px;
  left:10px;

}
.billet {
  background-color: white;
  border-radius: 10px;
  padding:15px;
  position: absolute;
  left: 80px;
  top:50%;
  width: 250px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: none;
  z-index: 999;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}
.bobble:hover + .billet {
  display: block;
}
.active-pointer {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: absolute;
  left: 80px;
  top:50%;
}

.billet:after, .billet:before, .active-pointer:after, .active-pointer:before {
  right: 99%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

}

.billet:after, .active-pointer:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fffffffffffffffffff;
  border-width: 20px;
  margin-top: -20px;

}
.billet:before, .active-pointer:before {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 23px;
  margin-top: -23px;

}


h3 {
  font-weight: 400;
  font-size: 24px;
  float: left;
  border-bottom: 1px dotted black;
  position: relative;
  margin-top: 20px;
  margin-left: 5px;
  margin-bottom:20px;

}
.divider {
  height: 1px;
  background-color: #ddd;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin: 20px;
  margin-top: 20px;

}
.piperine {

}
.pillepalle {
  position: relative;
  width: 100%;
  float: left;
  height: 38px

}
.connector-vertical {
  width: 1px;
  height: 20px;
  position: absolute;
  left:10px;
  top:0;
  border-left: 1px dotted red;
  background-color: transparent !important;
}
.connector-horizontal {
  height: 1px;
  width: 20px;
  position: absolute;
  left:10px;
  top:20px;
  border-bottom: 1px dotted red;
  background-color: transparent !important;
}
.pille {
  border-radius: 4px;
  background-color: red;
  color: white;
  height: 30px;
  width: auto;
  position: absolute;
  left: 30px;
  top:5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  border: none !important;
}
.tool * {
  background-color: $maincolor;
  border-color: $maincolor;
}
.article * {
  background-color: #ababab;
  border-color: #ababab;
}
.third-level * {
  background-color: #5b5b5b;
  border-color: #5b5b5b;
}
.conflict > * {
  background-color: #D72222;
  border-color: #D72222;
}
.warning-curtain {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  z-index: 998;
  top:0;
  left: 0;
}
.warning-overlay {
  width: 80%;
  top:100px;
  left:50%;
  webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding-bottom: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  position: fixed;
  z-index: 999;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}
.warning-title {
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -10px;
  width: calc(100% + 40px);
  background-color: #f1f1f1;
  padding:5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 20px;
}
.warning-body {
  width: 100%;
}
.warning-sign {
  float: left;
  position: relative;
  width: 128px;
  height: 116px;
  background-image: url('../img/waarschuwing-red.svg');
  -webkit-background-size: 128px 116px;
  background-size: 128px 116px;
  margin-bottom: 20px;
}
.warning-text {
  float: left;
  width: calc(100% - 128px);
  padding-left: 20px;

  margin-bottom: 20px;
}
.warning-shouty {
  font-size: 24px;
}

.tablesorter-filter {
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.norm-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.previous_revision_change {
  margin-left: 15px;
  margin-bottom: 15px;
  font-family: "Courier New", "Courier", sans-serif;
}

.previous_revision_change .previous_value {
  color: #910008;
  text-decoration: line-through;
}

ins {
  color: green;
  text-decoration: none;
}

del {
  color: #910008;
  text-decoration: line-through;
}

.btn.focus, .btn:focus, .btn:hover {
  color: white;
}

.mytasks-ul a, .mytasks-ul a:hover, .mytasks-ul a:visited {
  color: black;
  text-decoration: none;
}
.input-group-addon {
 .fa {
   font-size: 20px;
  }
}

.product_seperator {
  height: 40px;
  width: 100%;
}