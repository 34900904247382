/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */

/*
 * Mixin for clearfix
 * @include clearfix;
*/
@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}


/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */
@mixin fontface($font-family, $font-url, $font-name) {
  @font-face {
    font: {
      family: $font-family;
      style: normal;
      weight: normal;
    }

    src: url($font-url + '/' + $font-name + '.eot');
    src: url($font-url + '/' + $font-name + '.eot#iefix') format('embedded-opentype'),
    url($font-url + '/' + $font-name + '.woff') format('woff'),
    url($font-url + '/' + $font-name + '.ttf')  format('truetype'),
    url($font-url + '/' + $font-name + '.svg#' + $font-name) format('svg');
  }
}


/**
 * IMAGE RETINA
 * @include image-2x(/img/image.png, 100%, auto);
 */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}


/* ----------------------------------------------------------------------------
 * Responsive
 * Usage

    @include r(240)  { }
    @include r(320)  { }
    @include r(480)  { }
    @include r(768)  { }
    @include r(1024) { }
    @include r(1366) { }

 * ------------------------------------------------------------------------- */

@mixin r($point) {
  @if $point == 240 {
    @media (min-width: 240px) { @content; }
  }
  @if $point == 320 {
    @media (min-width: 320px) { @content; }
  }
  @if $point == 480 {
    @media (min-width: 480px) { @content; }
  }
  @if $point == 600 {
    @media (min-width: 600px) { @content; }
  }
  @if $point == 768 {
    @media (min-width: 768px) { @content; }
  }
  @if $point == 1024 {
    @media (min-width: 1024px) { @content; }
  }
  @if $point == 1140 {
    @media (min-width: 1140px) { @content; }
  }
  @if $point == 1280 {
    @media (min-width: 1280px) { @content; }
  }
  @if $point == 1366 {
    @media (max-width: 1366px) { @content; }
  }
  @if $point == 1440 {
    @media (max-width: 1440px) { @content; }
  }
}

@mixin m($point) {
  @if $point == 414 {
    @media (max-width: 414px) { @content; }
  }
  @if $point == 1366 {
    @media (min-width: 1366px) { @content; }
  }
  @if $point == 1440 {
    @media (min-width: 1440px) { @content; }
  }
}

@mixin mr($point) {
  @if $point == 414 {
    @media (min-width: 414px) and (max-width: 1440px) { @content; }
  }
}