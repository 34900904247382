
body {
  color: #323232;
  font-family: 'Open Sans', sans-serif !important;
  padding: 0px;
  margin: 0px;
  font-size: 15px;
  direction: ltr;
   font-weight: 400;
  background: #ffffff;
}

input {
  -webkit-appearance: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

input[type=radio] {

  -webkit-appearance: radio;
}


input[type=checkbox] {

  -webkit-appearance: checkbox;
}

input[type=file]{
  font-size: 15px;
}


.clear {
  clear: both;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't
 properly apply the media queries in Bootstrap's CSS. To address this,
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/*
 Internet Explorer 10 doesn't differentiate device width from viewport width,
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied
*/
@-ms-viewport {
  width: auto !important;
}
/* Custom scrollbars for webkit browsers */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
  border-left: 1px solid #cecece;
}

::-webkit-scrollbar-thumb {
  background-color: #cecece;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}



/* Horizontal break */
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}

.red {
  color: $maincolor;
  letter-spacing: normal;
}

.header {
  height: 46px;
  background: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ccc;
}


.header .logo {
  font-family: 'Alegreya Sans', sans-serif;
  color: #5b5b5b;
  font-size: 24px;
  letter-spacing: 7px;
  text-transform: uppercase;
  margin-left: 0px;
  position: absolute;
  margin-top: 0px;
  //background: url('../../img/logo_landscape.png');
  background-size: 235px 36px;
  width: 235px;
  height: 36px;
  margin-top: 4px;
}


.header .top-menu {
  position: absolute;
  right: 20px;
}


/***
Customized Bootstrap Dropdowns
***/
/***
Dropdowns
***/
.dropup.open > .dropdown-toggle,
.dropdown.open > .dropdown-toggle {
  border-color: #ddd;
}

/***
Dropdown Menu
***/
.dropdown-menu {
  min-width: 175px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  margin: 10px  0px 0px 0px;
  background-color: #fffff;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.dropdown-menu li.divider {
  background: #f1f3f6;
}
.dropdown-menu li > a {
  padding: 8px 14px;
  color: #555;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
}
.dropdown-menu li > a > [class^="fa-"],
.dropdown-menu li > a > [class*=" fa-"] {
  color: #888;
}
.dropdown-menu li > a > [class^="icon-"],
.dropdown-menu li > a > [class*=" icon-"] {
  color: #666;
}
.dropdown-menu li > a > [class^="glyphicon-"],
.dropdown-menu li > a > [class*=" glyphicon-"] {
  color: #888;
}
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}
.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.dropdown > .dropdown-menu,
.dropdown-toggle > .dropdown-menu,
.btn-group > .dropdown-menu {
  margin-top: 10px;
}
.dropdown > .dropdown-menu:before,
.dropdown-toggle > .dropdown-menu:before,
.btn-group > .dropdown-menu:before {
  position: absolute;
  top: -8px;
  right: 9px;
  left: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: '';
}
.dropdown > .dropdown-menu:after,
.dropdown-toggle > .dropdown-menu:after,
.btn-group > .dropdown-menu:after {
  position: absolute;
  top: -7px;
  right: 10px;
  left: auto;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: '';
}
.dropdown > .dropdown-menu.pull-left:before,
.dropdown-toggle > .dropdown-menu.pull-left:before,
.btn-group > .dropdown-menu.pull-left:before {
  left: auto;
  right: 9px;
}
.dropdown > .dropdown-menu.pull-left:after,
.dropdown-toggle > .dropdown-menu.pull-left:after,
.btn-group > .dropdown-menu.pull-left:after {
  left: auto;
  right: 10px;
}
.dropdown > .dropdown-menu.pull-right:before,
.dropdown-toggle > .dropdown-menu.pull-right:before,
.btn-group > .dropdown-menu.pull-right:before {
  left: auto;
  right: 9px;
}

.dropdown-menu .typcn {
  font-size: 20px;
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.dropdown.avatar img{
  float: left;
  margin-top: -5px;
  margin-right: 5px;
  height: 29px;
  display: inline-block;
}

.dropdown.avatar > a {
  color: #000;
}

.img-circle {
  border-radius: 50%;
}

@media (max-width: 768px) {

  .navbar-nav {
    margin: 0;
  }

  .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

  }

  .navbar-nav > li > a{
    height: 50px;
    padding-top: 15px;
  }
}

.nav-mobile {

  display: none;

}

.page-container {
  margin: 0px;
  padding: 0px;
  position: relative;
  margin-top: 47px;
}
.page-container:before, .page-container:after {
  content: " ";
  display: table;
}
.page-container:after {
  clear: both;
}

.page-sidebar {
  background-color: #F1F1F1;
  width: 235px;
  float: left;
  position: fixed;
  margin-right: -100%;

}

.current_time_div {
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
  margin-top: 27px;
  -webkit-transform-style: preserve-3d;
}

.current_time {
  font-size: 58px;
  font-weight: 200;
  line-height: 52px;
}

.current_date {
  font-weight: 400;
  font-size: 30px;
}

.page-sidebar .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}


.page-sidebar.navbar-collapse {
  padding: 0;
  box-shadow: none;
}
.page-sidebar .page-sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;

}

.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:before, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after {
  content: " ";
  display: table;
}
.page-sidebar .page-sidebar-menu > li.sidebar-toggler-wrapper:after, .page-sidebar .page-sidebar-menu > li.sidebar-search-wrapper:after {
  clear: both;
}

.page-sidebar .page-sidebar-menu > li > a {
  display: block;
  position: relative;
  margin: 0;
  border: 0px;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}
.page-sidebar .page-sidebar-menu > li > a > i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow: none;
}

.page-sidebar .page-sidebar-menu > li.active > a > .selected {
  display: block;
  background-image: none;
  float: right;
  position: absolute;
  right: 0px;
  top: 16px;
  background: none;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid white;
}

.page-sidebar .page-sidebar-menu li > a > .arrow:before {
  float: right;
  width: 10px;
  text-align: center;
  margin-top: 4px;
  margin-right: 5px;
  margin-left: 5px;
  display: inline;
  font-size: 20px;
  font-family: FontAwesome;
  height: auto;
  content: "\f104";
  font-weight: 400;
  text-shadow: none;
}
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
  content: "\f107";
  font-size: 20px;
}

.page-sidebar .page-sidebar-menu .sub-menu {
  list-style: none;
  display: none;
  padding: 0;
  margin: 8px 0px 8px 0px;
}

.page-sidebar .page-sidebar-menu .sub-menu li > a {
  display: block;
  margin: 0;
  padding: 6px 15px 6px 43px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  background: none;
}


.page-sidebar .page-sidebar-menu li.active > .sub-menu {
  display: block;
}


.page-sidebar .sidebar-toggler {
  display: block;
  cursor: pointer;
  opacity: 0.7;
  filter: alpha(opacity=70);
  width: 30px;
  height: 27px;
  margin-top: 15px;
  margin-right: 19px;
  float: right;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.page-sidebar .page-sidebar-menu > li > a {
  border-top: 1px solid #484848;

  color: #5b5b5b;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;


}
.page-sidebar .page-sidebar-menu > li:last-of-type > a {
  border-bottom: 1px solid #484848 !important;
}

.page-sidebar .page-sidebar-menu > li > a > i {
  color: #5b5b5b;
  font-size: 20px;
}

.page-sidebar .page-sidebar-menu > li:hover > a,
.page-sidebar .page-sidebar-menu > li.open > a {
  background: #b5b5b5;
  color: #5b5b5b;

}

.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active.open > a {
  background: $maincolor;
  border-top-color: transparent;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 24px;
}

.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu > li.active.open > a > i {
  color: white;
}

.page-sidebar .page-sidebar-menu > li.active + li > a {
  border-top-color: transparent;
}

.page-sidebar .page-sidebar-menu > li.active.open + li > a {
  border-top-color: #484848;
}

/*.page-sidebar .page-sidebar-menu > li:last-child > a {
  border-bottom: 1px solid transparent !important;
}*/

.page-sidebar .page-sidebar-menu .sub-menu > li > a {
  color: #5b5b5b;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 20px;
  -webkit-transform-style: preserve-3d;
}

.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
  background: #b5b5b5 !important;
}

.page-content-wrapper {
  float: left;
  width: 100%;
}

.page-content-wrapper .page-content {
  margin-left: 115px;
  margin-top: 0px;
  min-height: 600px;
  padding: 10px 20px 10px 20px;
}
.page-content-wrapper .page-content.no-min-height {
  min-height: auto;
}

/***
Page footer
***/
.page-footer {
  padding: 8px 20px 5px 20px;
  font-size: 12px;
  height: 33px;
}
.page-footer:before, .page-footer:after {
  content: " ";
  display: table;
}
.page-footer:after {
  clear: both;
}
.page-footer .page-footer-inner {
  float: left;
  display: inline-block;
}
.page-footer-fixed.page-footer-fixed-mobile .page-footer {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
}
.page-footer-fixed.page-footer-fixed-mobile.page-sidebar-fixed .page-footer {
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  /* 992px */
  /* Default footer */
  .page-footer {
    clear: left;
  }

  /* Fixed footer */
  .page-footer-fixed .page-footer {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
  }

  /* Footer with footer sidebar */
  .page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-left: 45px;
  }
  .page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0 !important;
  }

  /* Fixed Sidebar */
  .page-sidebar-fixed .page-footer {
    margin-left: 235px;
    padding: 8px 20px 5px 20px;
  }

  /* Boxed page */
  .page-boxed .page-footer {
    padding: 8px 0 5px 0;
  }
  .page-boxed.page-sidebar-fixed .page-footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  /* Page sidebar reversed */
  .page-sidebar-reversed.page-sidebar-fixed .page-footer {
    margin-left: 0;
    margin-right: 235px;
    padding: 8px 20px 5px 20px;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-footer-fixed .page-footer {
    margin-left: 0;
    margin-right: 0;
  }
  .page-sidebar-reversed.page-sidebar-fixed.page-sidebar-closed .page-footer {
    margin-right: 45px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  /* Boxed Layout */
  .page-boxed .page-footer {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  /* 767px */
  /* Default footer & boxed footer */
  .page-footer,
  .page-boxed .page-footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Fixed footer */
  .page-footer-fixed .page-footer .container {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Scroll Top Top */
.scroll-to-top {
  display: inline-block;
  padding: 1px;
  text-align: center;
  position: fixed;
  bottom: 10px;
  z-index: 10001;
  display: none;
  right: 10px;
}
.scroll-to-top > i {
  display: inline-block;
  color: #687991;
  font-size: 30px;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.scroll-to-top:hover {
  cursor: pointer;
}
.scroll-to-top:hover > i {
  opacity: 1;
  filter: alpha(opacity=100);
}

@media (min-width: 992px) {
  /* 992px */
  .scroll-to-top {
    right: 20px;
  }
}
@media (max-width: 991px) {
  /* 991px */
  .scroll-to-top {
    bottom: 10px;
    right: 10px;
  }
  .scroll-to-top > i {
    font-size: 28px;
  }
}

.page-footer .page-footer-inner {
  color: #a3a3a3;
}
.page-footer-fixed .page-footer {
  background-color: #2b2b2b;
}

.page-sidebar-fixed:not(.page-footer-fixed) .page-content {
  border-bottom: 0;
}
.page-sidebar-fixed:not(.page-footer-fixed) .page-footer {
  background-color: #fff;
}
.page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
  color: #333;
}

.form-control::-moz-placeholder {
  color: #b3b3b3;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #b3b3b3;
}
.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
}

/*
.form-control {

  font-weight: normal;
  color: #333333;
  background-color: white;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-appearance: none;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 19px;
  font-weight: 400;
  border-radius: 0;

}
.form-control:focus {
  border-color: #999999;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
}

.input-icon {
  position: relative;
}
.input-icon > .form-control {
  padding-left: 33px;
}
.input-group .input-icon > .form-control {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-icon > i {
  color: #ccc !important;
  display: block;
  position: absolute;
  margin: 9px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;

}

.input-icon {
  border-left: 2px solid $maincolor !important;
}

.input-icon {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;

}
.input-icon .form-control {
  border-left: 0;
}*/

.button {

  margin: 0;
  height: 34px;
  background: $maincolor;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  outline: 0;
  border: none;
  padding-top: 4px;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px !important;


}

.button.gray {
  background: #ebebeb;
  color: black;
}



.content_box {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  margin-bottom: 20px;
}

.content_box.empty {
  background-color: transparent;
}

.content_box_header {
  background: #d6d6d6;
  width: 100%;
  height: 50px;
  padding-left: 15px;
  padding-top: 8px;
  color: #5b5b5b;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.content_box_content {
  padding: 15px;
  width: 100%;
  font-size: 16px;
}



.form_left {
  position: relative;
  float: left !important;
}

.add_button {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  text-align: right;
  padding-right: 10px;
}

.content_box_sort {
  width: 5%;
  position: relative;
  float: left;
  text-align: left;
  padding-right: 10px;
}

.conten_box_title {
  width: 95%;
  position: relative;
  float: left;
}


.content_box_trash {
  width: 5%;
  position: relative;
  float: left;
  text-align: right;
  padding-right: 10px;
}

.edit_question {
  cursor: text;
}
.edit_answer {
  cursor: text;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: none repeat scroll 0 0 #007BFF;
  cursor: inherit;
  display: block;
  border: 1px solid #333333;
}

.current_time_div {
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
  margin-top: 27px;
}

.current_time {
  font-size: 58px;
  font-weight: 200;
  line-height: 52px;
}

.current_date {
  font-weight: 400;
  font-size: 30px;
}

.content_box {
  width: 100%;
  position: relative;
  background: white;
  border-radius: 10px;
  max-width: 1212px;
  margin: auto;
  margin-bottom: 26px;

}

.content_box .title, .content_box .title_edit  {
  width: 100%;
  position: relative;
  background-color: #d6d6d6;
  color: #2c2c2c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 34px;
  padding-left: 30px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 21px;
}


.content_box .title.only {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.content_box .content {
  width: 100%;
  position: relative;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 21px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 25px;
  padding-right: 25px;
  color: #7a7a7a;

}

.clock_div {
  padding-top: 26px;
  width: 100%;
  text-align: center;
  margin: auto;
  height: 206px;
}

.clock {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 160px;
  font-weight: 200;
  line-height: 160px;
}



@media (max-width: 676px) {
  .clock {
    font-size: 80px;
    line-height: 80px;
  }
  .clock_div {
    height: 113px;
    padding-top: 10px;
  }

}

.icon_holder {
  background-color: $maincolor;
  color: white;
  width: 23px;
  height: 23px;
  border-radius: 3px;
  float: left;
  position: relative;
  line-height: 20px;
  margin-top: 3px;
  padding-top: 1px;
  padding-left: 1px;
  outline: 0;
  border: none;
  text-align: center;
}

.icon_holder.add_button {
  background-color: #45b688;
  margin-left: 10px;
  margin-top:5px;
  cursor:pointer;
}

.icon_holder.edit_button {
  background-color: $maincolor;
  margin-left: 10px;
  margin-top:5px;
  cursor:pointer;
}

.icon_holder.undo_button {
  background-color: #7d7d7d;
  margin-left: 10px;
  margin-top:5px;
  cursor:pointer;
}

.icon_holder.cancel_button {
  color: #7d7d7d;
  margin-left: 10px;
  cursor:pointer;
  background-color: white;

}

.icon_holder.accept_button {
  background-color: white;
  cursor:pointer;
  color: $maincolor;
}

.icon_holder .fa {
  color: $neutrikgreen;
}

.content_title {
  font-size: 58px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;

}

.content_subtitle {
  font-size: 32px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}

.error  {
  color: $maincolor;
  font-size: 18px;
}

.edit {
  cursor: pointer;
}
/*
.delete_tr {
	display: none;
}

.delete_tr > td{
	background: $maincolor;

}

.delete_confirmation {
	color: 	white;
	font-size: 18px;
	font-weight: 400;
	padding-left: 10px;
	float: left;
	position: relative;
}

.delete_confirmation_buttons {
	position: relative;
	float: right;
	padding-right: 10px;
}
*/
.group_title {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 25px;
  font-weight: 400;
  color: #333333;
}

.form-error, .has-error {
  border: 1px solid #DB4C46 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.export_statistics {
  position: absolute;
  right: 25px;
}

.btn-export {
  background: $maincolor;
  color: white;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  font-size: 18px;


}

.btn-export .typcn-eye {
  font-size: 21px;
}

.btn-export:hover,
.btn-export:focus,
.btn-export.focus,
.btn-export:active,
.btn-export.active,
.open > .dropdown-toggle.btn-export {
  background: $maincolor;
  color: white;
}

.export-menu {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  background: $maincolor;

  top: 35px;
}

.export-menu > li > a {
  color: white;
}

.export-menu > li:hover > a {
  color: white;
  background: $maincolor;

}

.add_protocol_category {
  position: relative;
  float: left;
  margin-top: 37px;
}

.add_protocol {
  position: absolute;
  right: 25px;
}

.download_protocol {
  position: relative;
  float: right;
}

.datepicker {
  z-index: 9999 !important;
}

.back_button {
  position: absolute;
  left: 255px;
}

@media (max-width: 995px) {
  .back_button {
    display: none;
  }
}

.ui-autocomplete {
  z-index: 9999;
}

.error_message {
  color: $maincolor;
}

.fa.white {
  color: white;
  font-size: 18px;

}

.input-icon .typcn {
  font-size: 20px;
  line-height: 10px;
}

.inputs_left {
  width: 100%;
  position: relative;

  padding-right: 130px;

}

.buttons_right {
  position: absolute;
  width: 110px;
  right: 15px;
  top: 45px;
}

.buttons_right_bottom {
  position: absolute;
  width: 110px;
  right: 15px;
  bottom: 35px;
}

.input_fields {
  padding-top: 10px;
  position: relative;
  clear: both;
}

.input_fields.no-padding {
  padding-top: 0px;
}

.icon_label {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 21px;
  color: #7a7a7a;
  float: left;
  position: relative;
  margin-left: 5px;
}

.icon_holder .fa-sitemap {
  padding-left: 2px;
}

.icon_holder .fa-pencil {
  padding-left: 4px;
}

.icon_holder .fa-header {
  padding-left: 3px;
}

.icon_holder .fa-expand {
  padding-left: 2px;
}

.icon_holder .fa-picture-o {
  padding-left: 2px;
}

.icon_holder .fa-industry {
  padding-left: 3px;
}

.icon_holder .fa-truck {
  padding-left: 2px;
}

.icon_holder .fa-database {
  padding-left: 3px;
}

.icon_holder .fa-product-hunt {
  padding-left: 3px;
}

.icon_holder .fa-paint-brush {
  padding-left: 3px;
}
.icon_holder .fa-shopping-bag {
  padding-left: 3px;
}

.icon_holder .fa-check-circle {
  padding-left: 3px;
}

.icon_holder .fa-plus {
  padding-left: 3px;
}

.icon_holder .fa-search {
  padding-left: 3px;
}

.icon_holder .fa-folder-open {
  padding-left: 3px;
}

.icon_holder .fa-list-ol {
  padding-left: 2px;
}

.icon_holder .fa-globe {
  padding-left: 3px;
}

.icon_holder .fa-user {
  padding-left: 4px;
}

.icon_holder .fa-fire-extinguisher {
  padding-left: 3px;
}

.icon_holder .fa-map-marker {
  padding-left: 6px;
}

.icon_holder .fa-list-ol {
  padding-left: 2px;
}

.icon_holder .typcn-upload {
  padding-left: 2px;
}

.icon_holder .fa-youtube {
  padding-left: 3px;

}

.icon_holder .fa-qrcode {
  padding-left: 3px;

}

.icon_holder .fa-fire {
  padding-left: 3px;

}

.icon_holder .fa-drupal {
  padding-left: 3px;

}

.icon_holder .fa-scissors {
  padding-left: 3px;

}

.icon_holder .fa-link {
  padding-left: 2px;
}

.category_title td {
  color: #ffffff !important;
  background-color: #999;
}
.category_title td:first-of-type {
  text-align: center;

}
.button-array {
  margin: auto auto 26px;
  max-width: 1212px;
  position: relative;
  width: 100%;
  text-align: right;

}
.button-array button {

  width:auto;

}

@media (max-width: 991px) {

  .page-sidebar-wrapper {
    display: none;
  }

  .page-content-wrapper .page-content {
    margin: 0px !important;
    padding: 10px 10px 10px 10px !important;
    min-height: 280px;

  }
}

@media (max-width: 991px) {
  .inputs_left {
    width: 100%;
    position: relative;

    padding-right: 0;

  }

  .buttons_right {
    position: relative;
    width: 100%;
    right: 0px;
    top: 0px;
  }

  .content_title {
    font-size: 32px;
  }

  .content_subtitle {
    font-size: 20px;
  }

  .top-menu {
    margin-right: 20px !important;
    display: none !important;
  }

  .page-header {
    position: fixed !important;
  }

  .page-container {
    margin-top: 47px !important;
  }

  .nav-mobile {
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    z-index: 9999 !important;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
    min-height: 100%;
    -webkit-backface-visibility: hidden;
  }

  .show_mobile_nav {
    transform: translateX(-200px);
    -webkit-transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    -o-transform: translateX(-200px);

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;

    -webkit-backface-visibility: hidden;
  }

  .hide_mobile_nav {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
  }

  .menu-icon-mobile {
    //background: url(../../img/menu_default_dark.svg);
    width: 24px;
    height: 16px;
    background-size: 24px 16px;
    right: 20px;
    top: 17px;
    z-index: 99;
    position: absolute;
    cursor: pointer;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    display: block;
  }

  .closeMenu {
    //background: url(../../img/menu_expanded_dark.svg);
    width: 16px;
    height: 16px;
    position: absolute;
    background-size: 16px 16px;
    right: 24px;
    top: 17px;
    cursor: pointer;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    display: none;
    z-index: 999;
  }

  .nav-mobile .menu-container {
    background: white;
    position: absolute;
    left: 0px;
    width: 100%;
    top: 0;
    height: 100%;
    background: #D6D6D6;
    display: block;
    min-height: 100%;
  }

  .nav-mobile .menu-container ul.mobile-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

  }

  .nav-mobile .menu-container ul.mobile-menu > li {
    width: 100%;
    height: 47px;
    border-bottom: 1px solid #DFDFDF;
    padding-left: 25px;
    padding-top: 10px;
    background: #D6D6D6;
  }

  .nav-mobile .menu-container ul.mobile-menu > li > a {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 19px;
    font-weight: 400;
    color: rgba(118,118,118,1);
    text-transform: uppercase;
    text-decoration: none !important;
    border-bottom: none !important;

  }

  .nav-mobile .menu-container ul.mobile-menu > li > a:hover, .nav-mobile .menu-container ul.mobile-menu > li > a.active {

    color: rgba(42,42,42,1);
    text-decoration: none !important;
    border-bottom: none !important;

  }

  .page-breadcrumb > li > a {
    font-size: 16px !important;
  }

  .page-breadcrumb > li > i {
    font-size: 16px !important;
  }

  .page-bar {
    margin-top: -10px;
  }

}

@media (max-width: 767px) {

  .button {
    width: 100%;
  }

  .header .logo {

    letter-spacing: 5px;

  }

}

.back_button {
  position: absolute;
  left: 255px;
}

@media (max-width: 995px) {
  .back_button {
    display: none;
  }
}


/* Page breadcrumb */
.page-bar {
  padding: 0px;
  margin-bottom: 25px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.page-bar:before, .page-bar:after {
  content: " ";
  display: table;
}
.page-bar:after {
  clear: both;
}
.page-bar .page-breadcrumb {
  display: inline-block;
  float: left;
  padding: 8px;
  margin: 0;
  list-style: none;
}
.page-bar .page-breadcrumb > li {
  display: inline-block;
}
.ie8 .page-bar .page-breadcrumb > li {
  margin-right: 1px;
}
.page-bar .page-breadcrumb > li > a,
.page-bar .page-breadcrumb > li > span {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif !important;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i {
  color: #444;
  font-size: 16px;
  text-shadow: none;
}
.page-bar .page-breadcrumb > li > i[class^="icon-"],
.page-bar .page-breadcrumb > li > i[class*="icon-"] {
  color: gray;
}

.page-breadcrumb > li > a {
  font-size: 21px !important;
}

.page-breadcrumb > li > i {
  font-size: 21px !important;
}

@media (max-width: 991px) {
  .page-breadcrumb > li > a {
    font-size: 16px !important;
  }

  .page-breadcrumb > li > i {
    font-size: 16px !important;
  }
}

.sub_content_box {
  background: #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}

.dragndroparea {

  color: white;
  background: $maincolor;
  opacity: 0.5;
  height: 60px;
  width: 100%;
  border-radius: 3px;
  padding: 15px;
  margin-top: 10px;

}

.thumb_td {
  width: 40px !important;
}

.preview-thumb {
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.ajax-spinner {
  //background-image: url(../../../public/img/ajax-spinner.gif);
  width: 32px;
  height: 32px;
  position: relative;
  display: none;
}

.table_content th.center {
  text-align: center !important;
}

.bestellungs_filter {
  width: 100%;

  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}

table.customer_table {
  font-size: 18px;
}

table.customer_table td:first-child {
  min-width: 150px;
  font-weight: bold;
}

.tbl-cart thead{
  background: #f8f8f8
}
.tbl-cart .td-qty{
  white-space: normal !important;
  width: 130px;
}
.btns-cart .btn{
  margin-right: 3px;
}
.tbl-checkout tbody tr td:nth-child(odd){
  background: #f8f8f8;
}

.cart-image {
  max-height: 50px;
  max-width: 50px;
}

.tbl-cart {
  font-size: 18px;
}

.tbl-cart td, .tbl-cart th {
  padding-left: 5px !important;
}

.cart_variantes {
  color: #666;
  font-size: 16px;
}

.sum_table {
  float: right;
  border-spacing: 0px;
  border-collapse: collapse;
  margin-bottom: 30px;
  font-size: 18px;
}

.sum_table td {
  border: 1px solid #C0C0C0;
  padding: 5px;
  vertical-align: top;
  background-color: #F7F7F7;
  text-align: right;
}

.sum_table td:first-child {
  min-width: 234px;
}

.sum_table td:last-child {
  min-width: 150px;
}

.sum_table tr.price_sum {
  font-size: 18px;
  font-weight: bold;
}

.bestellungs_filter {
  width: 100%;

  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}

.artnr {
  margin-top: 0px;
  font-size: 16px;
}
.form-error {
  border: 1px solid #920404;
}


label {
  font-weight: normal !important;
}

.help-block {
  color: #DB4C46;
}

.button-small > a > i {
  color: white;
}
.filtered {
  display:none;
}

.tablesorter-blue .header,
.tablesorter-blue .tablesorter-header {
  /* black (unsorted) double arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  /* white (unsorted) double arrow */
  /*background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);*/
  /* image */
  /* background-image: url(images/black-unsorted.gif); */
  background-repeat: no-repeat;
  background-position: center right;
  padding: 4px 18px 4px 4px;
  white-space: normal;
  cursor: pointer;
}
.tablesorter-blue .headerSortUp,
.tablesorter-blue .tablesorter-headerSortUp,
.tablesorter-blue .tablesorter-headerAsc {
  outline: none;
  /* black asc arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  /* white asc arrow */
  /* background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7); */
  /* image */
  /* background-image: url(images/black-asc.gif); */
}
.tablesorter-blue .headerSortDown,
.tablesorter-blue .tablesorter-headerSortDown,
.tablesorter-blue .tablesorter-headerDesc {
  outline: none;
  /* black desc arrow */
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  /* white desc arrow */
  /* background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7); */
  /* image */
  /* background-image: url(images/black-desc.gif); */
}
.tablesorter-blue thead .sorter-false {
  background-image: none;
  cursor: default;
  padding: 4px;
}

.delayed_task_reason_counter {
  float: right;
  position: relative;
  text-align: right;
  margin-top: 15px;
}

.delayed_task_reason_error {
  margin-top: 15px;
  color: #D72222;
  position: relative;
  float: left;

}

.createchange_dependencies .divider {
  display: none !important;
}

div.has-error ul.select2-choices {
  border-color: #DB4C46 !important;
}

.qq-upload-button-selector.qq-upload-button {
  background-color: $maincolor;
  color:white;
  border-radius: 5px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  padding-top:5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  width: 150px;
  box-shadow: none;
}
.qq-upload-delete {
  width: 20px !important;
  height: 20px !important;
  background-color: $maincolor !important;
  border-radius: 3px !important;
  color: white !important;
  padding-left: 4px !important;
  float: left !important;
  position: relative !important;
  cursor: pointer !important;
  border:none !important;
  text-shadow: none !important;
}
.qq-upload-cancel  {
  width: 20px !important;
  height: 20px !important;
  background-color: $maincolor !important;
  border-radius: 3px !important;
  color: white !important;
  padding-left: 4px !important;
  float: left !important;
  position: relative !important;
  cursor: pointer !important;
  border:none !important;
  text-shadow: none !important;
}
.qq-progress-bar {
  background-color: $maincolor !important;
}
.qq-upload-list {
  padding-bottom: 40px!important;
  box-shadow: none !important;
}

.tablesorter-filter.disabled {
  display: none;
}

.filterhidler tr > td:nth-of-type(7) input {
  display: none;
}
